body{
  background: url("images/fondi.jpg") no-repeat;
  background-size: cover;
}

.App {
  text-align: center;
}

.maincontainer{
  background-color: white;
  padding: 2%;
}


.App-header {
  background-color:white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.badge{
  padding: 10px !important;
}


.badge-primary{
  background-color: #0d6efd;
}
.badge-danger{
  background-color: red;
}
.badge-success{
  background-color:green;
}

.badge-secondary{
  background-color:rgb(120, 120, 120);
}


.partenza.card{
  background:url('images/start.png') no-repeat white;
  background-position: 0 0;
  background-size:auto;
}
.controllo.card{
  background:url('images/checkpoint.png') no-repeat white;
  background-position: 0 0;
  background-size:auto;
}
.arrivo.card{
  background:url('images/finish.png') no-repeat white;
  background-position: 0 0;
  background-size: auto;
}
.controllitappa.card-body{
  padding: 0;
  background-color:lightgrey;
}

.completata{
  color:darkgreen;
}

.nomeconcorrente a, .nomeconcorrente a:hover, .nomeconcorrente a:visited , .nomeconcorrente a:focus {
  color:white;
}
.nomeconcorrente .dropdown-menu a{
  color:black
}

.modaletappe{
  width:80% !important;
  min-width: 80% !important;
}

.linkadmin, .linkadmin:hover,.linkadmin:visited{
  color:white;
  text-decoration: none;
}

tr.completo {
  background-color: chartreuse;
}
tr.ritirato {
  background-color: red;
}

.listaconcorrenti{
  border:1 px solid red
}